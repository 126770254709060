import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import './index.css';
import PageSpinner from './PageSpinner';
import PrivateRoute from "./components/private-route/PrivateRoute";
import { toast, ToastContainer} from "react-toastify";
import NotFound from "./NotFound";
// import PlanDetails from "./views/plandetails"
import { changePassword } from "actions/users";


const Home            = React.lazy(() =>import ("views/home.js"));
const Dashboard       = React.lazy(() =>import ("views/dashboard.js"));
const Privacy         = React.lazy(() =>import ("views/privacy.js"));
const Terms           = React.lazy(() =>import ("views/terms.js"));
const Disclaimer      = React.lazy(() =>import ("views/disclaimer.js"));
const Referal         = React.lazy(() =>import  ("views/referral.js"));
const SiteDown        = React.lazy(() =>import ("views/sitedown"));

  
ReactDOM.render(
  <BrowserRouter basename="/"> 
    <ToastContainer/>
     <Switch>
      <React.Suspense fallback={<PageSpinner />}>
      <Route exact path="/referral" component={Referal} />
      <Route exact path="/dashboard" component={Dashboard} />
       <Route exact path="/dashboard/:address" component={Dashboard} />
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/sitedown" component={SiteDown} />
      <Route exact path="/terms-of-use" component={Terms} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      </React.Suspense>

    </Switch>

  </BrowserRouter>,
  document.getElementById("root")
);